import { useEffect, useState } from "react";
import getNowPlayingItem from "./SpotifyApi";
import "./SpotifyPlayer.css";

function SpotifyPlayer(props) {
  const [result, setResult] = useState({});

  //console.log(result);

  useEffect(() => {
    Promise.all([
      getNowPlayingItem(
        props.client_id,
        props.client_secret,
        props.refresh_token
      ),
    ]).then((results) => {
      setResult(results[0]);
    });
  }, [props]);

  return result.isPlaying ? (
    <div className="nowplayingcard">
      <div className="nowplayingcontainer-inner">
      <a href={result.songUrl}  target="_blank" rel="noreferrer"><img id="trackart" src={result.albumImageUrl} alt={result.title}></img></a>
        <div className="trackInfo">
          <a href={result.songUrl} target="_blank" rel="noreferrer" id="tracktitle">{result.title}</a>
          <a href={result.songUrl} target="_blank" rel="noreferrer" id="trackartist">
            {result.artist}
          </a>
        </div>
      </div>
    </div>
  ) : (
    <div>Não estou escutando nada agora.</div>
  );
}

export default SpotifyPlayer;