import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/foto.png";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";



function Home2() {

  function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              DEIXE EU ME <span className="purple">APRESENTAR</span>
            </h1>
            <p className="home-about-body">
              Me apaixonei por programação aos 14 anos de idade, desde então programar, computador e tecnologia fazem parte da minha vida.<br />
              Com mais de 20 anos de experiência, presenciei grandes mudanças e sempre me adaptando às novas tecnologias/metodologias.<br />
              Atualmente tenho {getAge("1986/04/27")} anos.
              <br />
              <br />Utilizo diariamente
              <i>
                <b className="purple"> C#, Docker, NodeJS, Mongo, Redis, Mysql, SQL entre outras... </b>
              </i>
              <br />
              <br />
              Sempre que possível estou praticando <b className="purple">Linux</b> e aprendendo novas tecnologias em <b className="purple"> front-end, back-end e infra</b>.
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>Redes</h1>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/danilobbezerra"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/danilobbezerra/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/danilobbezerra/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
