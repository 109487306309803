import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  SiLinux,
  SiVisualstudiocode,
  SiPostman,
  SiWindows,
  SiUbuntu,
  SiAzuredevops,
  SiElastic,
  SiKibana,
  SiNginx,
  SiAmazonaws,
  SiJenkins,
} from "react-icons/si";

function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>    
      <Col xs={4} md={2} className="tech-icons">
        <SiLinux />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiUbuntu />
      </Col>           
      <Col xs={4} md={2} className="tech-icons">
        <SiWindows />
      </Col>                           
      <Col xs={4} md={2} className="tech-icons">
        <SiVisualstudiocode />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPostman />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiElastic />
      </Col>   
      <Col xs={4} md={2} className="tech-icons">
        <SiKibana />
      </Col>   
      <Col xs={4} md={2} className="tech-icons">
        <SiNginx />
      </Col>     
      <Col xs={4} md={2} className="tech-icons">
        <SiAmazonaws />
      </Col>       
      <Col xs={4} md={2} className="tech-icons">
        <SiAzuredevops />
      </Col>   
      <Col xs={4} md={2} className="tech-icons">
        <SiJenkins />
      </Col>                                  
    </Row>
  );
}

export default Toolstack;
