import React from "react";
import { Col, Row } from "react-bootstrap";
import { SiCsharp, SiDocker, SiDotnet } from "react-icons/si";
import {
  DiJavascript1,
  DiNodejs,
  DiMongodb,
  DiGit,
  DiMysql,
  DiTerminal,
  DiRedis,
  DiMsqlServer
} from "react-icons/di";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <SiCsharp />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
      <SiDotnet />
      </Col>      
      <Col xs={4} md={2} className="tech-icons">
        <SiDocker />
      </Col>      
      <Col xs={4} md={2} className="tech-icons">
        <DiNodejs />
      </Col>      
      <Col xs={4} md={2} className="tech-icons">
        <DiJavascript1 />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiMongodb />
      </Col>   
      <Col xs={4} md={2} className="tech-icons">
        <DiRedis />
      </Col>          
      <Col xs={4} md={2} className="tech-icons">
        <DiMysql />
      </Col>      
      <Col xs={4} md={2} className="tech-icons">
        <DiMsqlServer />
      </Col>          
      <Col xs={4} md={2} className="tech-icons">
        <DiTerminal />
      </Col>         

      <Col xs={4} md={2} className="tech-icons">
        <DiGit />
      </Col>
    </Row>
  );
}

export default Techstack;
